import React, {useCallback, useEffect, useRef, useState} from 'react'
import "./Home.scss"
import Header from "../../components/header/Header";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";
import {animated as a, useInView, useScroll, useTrail} from "@react-spring/web";
import {makeRequest} from "../../makeRequest";
import Features from "../../components/Features/Features";


const Home = (props) => {

    // const [faq, setFAQ] = useState([]);

    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )


    const [Aboutref, springsAbout1] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 20,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )


    const [Aboutref2, springsAbout2] = useInView(
        () => ({
            from: {
                opacity: 0,
                transform: "scale(0%)"
            },
            to: {
                opacity: 1,
                transform: "scale(100%)"
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    const [refMock1, springsM1] = useInView(
        () => ({
            delay:2500,
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )


    const [refMock2, springsM2] = useInView(
        () => ({
            delay:3800,
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        },
    )

    const [refFeat1, springsF1] = useInView(
        () => ({
            from: {
                opacity: 0,
                transform: "scale(0%)"
            },
            to: {
                opacity: 1,
                transform: "scale(100%)"
            },
        }),
        {
            rootMargin: '-100px 0%',
            once: true
        }
    )


    const [refMock3, springsM3] = useInView(
        () => ({
            from: {
                opacity: 0,

            },
            to: {
                opacity: 1,

            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )
    const [refFeat2, springsF2] = useInView(
        () => ({
            from: {
                opacity: 0,
                transform: "scale(0%)"
            },
            to: {
                opacity: 1,
                transform: "scale(100%)"
            },
        }),
        {
            rootMargin: '-100px 0%',
            once: true
        }
    )

    const [refMock4, springsM4] = useInView(
        () => ({
            from: {
                opacity: 0,

            },
            to: {
                opacity: 1,

            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    const [refFeat3, springsF3] = useInView(
        () => ({
            from: {
                opacity: 0,
                transform: "scale(0%)"
            },
            to: {
                opacity: 1,
                transform: "scale(100%)"
            },
        }),
        {
            rootMargin: '-100px 0%',
            once: true
        }
    )
    const mockup1Ref = useRef(null);
    const mockup2Ref = useRef(null);
    useEffect(() => {

        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    return (
        <a.div className='home'>

            <Loading/>
            <Header/>

            <a.div ref={Aboutref} style={springsAbout1} className="min-h-[100vh] relative flex items-center justify-center px-4">
                <div id="image-section"
                     className="relative container flex flex-col p-4 z-10 rounded-md flex items-center justify-center">
                    <a.div ref={Aboutref2} style={springsAbout2} className={"flex flex-col md:max-w-[700px] bg-white bg-opacity-75 p-5"}>
                        <h1 className="font-rexton base-md mb-3">About us</h1>
                        <p className="font-muli text-sm">
                            LIKA came to existence from a realization that shared experiences are the strongest bonds
                            people form. Through shared experiences, we form memories, snippets of our lives that stay
                            with us as long as we live.
                            <br/><br/>
                            That need to connect, is what inspired LIKA. With its name, meaning 'to get together' in
                            Arabic, we created an all-in-one solution for social interaction aka social planning.
                            <br/><br/>
                            LIKA Elevates your social life by creating custom plans tailored to your interests.
                            <br/><br/>
                            Our app streamlines the discovery, organizing, sorting, and storing of your experiences and
                            memories - all in one seamless platform.
                        </p>
                    </a.div>
                </div>
                <img src="img/stock/1.jpg" className="polaroid top-right rotate-3"/>
                <img src="img/stock/2.jpg" className="polaroid mid-right rotate-neg-2"/>
                <img src="img/stock/3.jpg" className="polaroid mid-left rotate-2"/>
                <img src="img/stock/4.jpg" className="polaroid bottom-left rotate-neg-3"/>
                <img src="img/stock/5.jpg" className="polaroid mid-bottom-left rotate-1"/>
            </a.div>

            <a.div ref={refFeat1} style={springsF1} className="min-h-[100vh] flex items-center justify-center container relative px-4">
                <div className={" flex flex-col-reverse md:flex-row  "}>
                    <div className="relative flex-1 p-4 z-10 rounded-md flex items-center justify-center">
                        <div className="flex flex-col flex-1 p-5">
                            <h1 className="font-rexton base-md mb-3">Discover</h1>
                            <p className="font-muli text-sm">
                                Choosing what to do is the first step in making plans. If you already have an idea, you
                                can create your own plan, like hosting a gathering at home or celebrating a friend's
                                birthday.
                                <br/><br/>
                                But if you're looking for inspiration, LIKA offers a variety of events and experiences
                                tailored to your interests. (Note: The more you plan, the better your suggestion
                                algorithm gets!) You can also explore further using the search page.
                                <br/><br/>
                                Our LIKA For You feature can also suggest events and experiences based on shared
                                interests between you and your friends.
                            </p>
                        </div>
                    </div>
                    <div className="image-container flex flex items-center justify-center flex-1">
                        <div className={"relative flex  items-center justify-center w-full"}>
                            <a.img ref={refMock1} style={springsM1} src="img/Discover1.png"
                                   className="mockup mockup-1"/>
                            <a.img ref={refMock2} style={springsM2} src="img/Discover2.png"
                                   className="mockup mockup-2"/>
                        </div>
                    </div>
                </div>
            </a.div>

            <a.div ref={refFeat2} style={springsF2} className="min-h-[100vh] flex items-center justify-center container relative px-4">
                <div className={" flex flex-col-reverse md:flex-row  "}>
                    <div className="relative flex-1 p-4 z-10 rounded-md flex items-center justify-center">
                        <div className="flex flex-col flex-1 p-5">
                            <h1 className="font-rexton base-md mb-3">ORGANIZE</h1>
                            <p className="font-muli  text-sm">
                                LIKA simplifies the planning process by integrating all the essential features to keep
                                you in control of your plan, these include:
                                <ul className={"list-disc ml-7"}>
                                    <li>Plan details
                                        <p className={"text-xsm"}>(About plan, cost, dresscode)</p>
                                    </li>
                                    <li>Location link</li>
                                    <li>Attendee management</li>
                                    <li>Reminders to pending invites</li>
                                    <li>Plan updates</li>
                                    <li>Plan focused chat</li>
                                    <li>Shared gallery</li>
                                </ul>
                                In short, we make sure you
                                never miss a thing!
                            </p>
                        </div>
                    </div>
                    <div className="image-container flex flex items-center justify-center flex-1">
                        <div className={"relative flex  items-center justify-center w-full"}>
                            <a.img ref={refMock3} style={springsM3} src="img/Plan.png"
                                   className="mockup"/>
                        </div>
                    </div>
                </div>
            </a.div>

    <a.div ref={refFeat3} style={springsF3} className="min-h-[100vh] flex items-center justify-center container relative px-4">
                <div className={" flex flex-col-reverse md:flex-row  "}>
                    <div className="relative flex-1 p-4 z-10 rounded-md flex items-center justify-center">
                        <div className="flex flex-col flex-1 p-5">
                            <h1 className="font-rexton base-md mb-3">Relive</h1>
                            <p className="font-muli  text-sm">
                                Now that your plan is done and
                                memories are made, it's time to upload those moments in the shared gallery.
                                <br/>
                                <br/>
                                Forget the hassle of asking for photos and videos with the old "Can you send us the pics from yesterday?". LIKA will auto send notifications to all attendees, gently reminding them to upload their memories for everyone to enjoy.
                                <br/>
                                <br/>
                                So, all the good times from that plan? They're neatly stored in one spot, easy
                                to re-live anytime.

                            </p>
                        </div>
                    </div>
                    <div className="image-container flex flex items-center justify-center flex-1">
                        <div className={"relative flex  items-center justify-center w-full"}>
                            <a.img ref={refMock4} style={springsM4} src="img/Memories.png"
                                   className="mockup "/>
                        </div>
                    </div>
                </div>
            </a.div>


            <div className={"services-section"}>
                <div className="container mx-auto section-padding flex flex-column justify-center relative">
                    <div className={"mx-auto w-full text-center flex flex-col md:flex-row p-5"}>
                        <div className={"card text-left flex flex-col justify-center items-start p-10 basis-1/3"}>
                            <p className={"font-rexton base-md"}>YOUR PLANS,{"\n"}
                                PERFECTED.</p>
                            <p className={"font-muli text-xxsm pt-2"}>NOW AVAILABLE</p>
                            <div className={"flex flex-row mt-3"}>
                                <div><a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img
                                    src={"img/download_app_store.png"}/></a></div>
                                <div><a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL}><img
                                    src={"img/download_google_play.png"}/></a></div>
                            </div>
                        </div>
                        <a.div ref={ref} class="flex flex-row flex-wrap basis-2/3">
                            <a.div ref={ref} style={springs} className={"text-start md:basis-1/2 p-3"}>
                                <p className={"font-muli text-xxsm uppercase text-gold"}>Always exciting</p>
                                <p className={"font-rexton pt-2 pb-2"}>quality events & experiences</p>
                                <p className={"font-muli text-sm "}>Only the best & top-rated - if they
                                    stand out, they’re on LIKA.</p>
                            </a.div>
                            <a.div ref={ref} style={springs} className={"text-start md:basis-1/2  p-3"}>
                                <p className={"font-muli text-xxsm uppercase text-gold"}>Always convenient</p>
                                <p className={"font-rexton pt-2 pb-2"}>SEAMLESS
                                    PLANNING</p>
                                <p className={"font-muli text-sm "}>Never miss an event again. Schedule, invite,
                                    and share event details with ease. </p>
                            </a.div>
                            <a.div ref={ref} style={springs} className={"text-start md:basis-1/2 p-3"}>
                                <p className={"font-muli text-xxsm uppercase text-gold"}>Always RELEVANT</p>
                                <p className={"font-rexton pt-2 pb-2"}>BASED ON YOU</p>
                                <p className={"font-muli text-sm "}>A curated selection of events perfectly
                                    matched to your interests.</p>
                            </a.div>
                            <a.div ref={ref} style={springs} className={"text-start md:basis-1/2  p-3"}>
                                <p className={"font-muli text-xxsm uppercase text-gold"}>Always memorable</p>
                                <p className={"font-rexton pt-2 pb-2"}>SHARED GALLERY</p>
                                <p className={"font-muli text-sm "}>Ensuring your memories last a lifetime.
                                    Relive your events through LIKA.</p>
                            </a.div>
                        </a.div>
                    </div>
                </div>
            </div>
            <Footer/>

        </a.div>
    )

}

export default Home