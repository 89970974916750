import React, {useCallback, useEffect, useState} from 'react'
import "./App.scss"

import Footer from "../../components/Footer/Footer";

import 'alpinejs';

const DownloadApp = (props) => {

    useEffect(() => {
            function RedirectUser() {
            const userAgent = navigator.userAgent.toLowerCase();
            if (/iphone|ipad|ipod/.test(userAgent)) {
            // Redirect to iOS App Store
            window.location.href = process.env.REACT_APP_IOS_URL; // Replace with your app's link
        } else if (/android/.test(userAgent)) {
            // Redirect to Google Play Store
            window.location.href = process.env.REACT_APP_ANDROID_URL; // Replace with your app's link
        } else {
            // User on a different device (optional: display a message)
            console.log("This app is not available for your device.");
        }
        }

        function trackEventWithUTM(eventName, utmSource, utmMedium) {
            window.gtag('event', eventName, {
                    'source': utmSource,
                    'medium': utmMedium,
                    'referral_code': utmMedium
            });
        }

        const urlParams = new URLSearchParams(window.location.search);
        const value = urlParams.get('r');

        if(value != null && value != ""){
            trackEventWithUTM("page_referrer", "influencer", value);
            setTimeout(() => {
                window.location.href = "https://likasocial.com/app?utm_source=inf&utm_medium=" + value;
            }, 500);
        }else {
            setTimeout(() => {
                RedirectUser()
            }, 1200);

        }
    }, []);

    return (
        <div className='download'>
            {/*<Loading/>*/}
            <div className={""}>
                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <p className={"font-rexton text-md pt-4 pb-0  uppercase text-white "}>Download </p>
                    <p className={"font-rexton text-md pb-4 pb-0 mb-5 uppercase text-white "}>Lika Social</p>
                    <div className={"flex flex-col"}>
                        <div>
                            <div className={"download-button"}><a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL}><img src={"img/google_download.png"} className={"w-100"}/></a></div>
                            <div className={"download-button mt-3"}><a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img src={"img/ios_download.png"} className={"w-100"}/></a></div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default DownloadApp