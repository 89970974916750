import React, {useCallback, useEffect, useState} from 'react'
import "./DataDeletion.scss"
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";

const DataDeletion = (props) => {

    // const [faq, setFAQ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    return (
        <div className='terms'>

            <Loading/>
            <div className={"header-section section-padding"}>
                <p className={"font-rexton pt-2 pb-2 uppercase"}>Data Deletion</p>
            </div>

            <div className={"container section-padding"}>

                <div>
                    <p>We at <span className="app-name"><b>Lika Social</b></span> respect your right to privacy and
                        control over your data. This policy outlines how you can delete your profile and associated data
                        from our mobile app.</p>


                    <h2 className={"mt-3 mb-2"}>
                        <b>Your Right to Delete Your Data</b>
                    </h2>

                    <p>You can request to remove your profile and all data associated with it at any time. Once you
                        submit a deletion request, we will permanently erase your information from our servers. This
                        includes:</p>

                    <ul>
                        <li>Account information (username, email address)</li>
                        <li>App usage data</li>
                        <li>Any other data collected and linked to your profile</li>
                    </ul>

                    <h2 className={"mt-3 mb-2"}>
                        <b>We Do Not Store Data After Deletion</b>
                    </h2>
                    <p>We are committed to complete data deletion. Once you request profile deletion, we will erase your
                        data from our servers and will not retain it for any purpose. </p>
                    <h2 className={"mt-3 mb-2"}>
                        <b>How to Delete Your Profile</b>
                    </h2>
                    <p>You can easily delete your profile and data by following these steps:</p>

                    <ul>
                        <li>Open the <span className="app-name"><b>Lika Social</b></span> mobile app and navigate to the
                            "Settings" page.
                        </li>
                        <li>Locate the "Delete Profile" option within the settings menu.</li>
                        <li>Confirm your request to delete your profile.</li>
                    </ul>
                    <p className={"p-3"}></p>
                    <p>Once confirmed, your profile and all associated data will be permanently removed. You will no
                        longer be able to access the app using the deleted profile.</p>
                    <p className={"p-3"}></p>
                    <p>Please note: Depending on your device's operating system (iOS or Android), you may need to
                        uninstall the app after profile deletion to ensure all local data is removed.</p>
                </div>
            </div>

            <Footer/>

        </div>
    )

}

export default DataDeletion