import React, {useCallback, useEffect, useState} from 'react'
import "./About.scss"
import Header from "../../components/header/Header";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";
import Features from "../../components/Features/Features";
import 'alpinejs';
import {animated as a, useInView, useScroll, useTrail} from "@react-spring/web";


const About = (props) => {

    // const [faq, setFAQ] = useState([]);

    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )
    const [ref2, springsFadeIn] = useInView(
        () => ({
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )
    const [ref3, springs3] = useInView(
        () => ({
            from: {
                opacity: 0,
                y:-30
            },
            to: {
                opacity: 1,
                y:0
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    const carousel_images = ["img/memory/memory_1.jpg", "img/memory/memory_2.jpg", "img/memory/memory_3.jpg", "img/memory/memory_4.jpg", "img/memory/memory_5.jpg", "img/memory/memory_6.jpg"]
    const carouselList = carousel_images.map((image, index) => {
        return (

            <div key={index} className={"memory-card flex flex-col align-center justify-center"}>
                <div className={"background"}>
                    <img className={"img-hover-zoom"} src={image}/>
                </div>
            </div>

        );
    });


    return (
        <div className='about'>

            {/*<Loading/>*/}
            <div ref={ref} className={"ourstory-section section-padding"}>
                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <p className={"font-rexton pt-2 pb-2 uppercase text-gold"}>Our story</p>
                    <a.div  style={springs} className={"medium-text-dev"}>
                        <p className={"font-muli text-xsm  text-white"}>Lika came to existence from a realization
                            that shared experiences are
                            the strongest bonds people form. Through shared experiences, we form
                            memories, snippets of our lives that stay with us as long as we live.
                        </p>
                        <br/>
                        <p className={"font-muli text-xsm  text-white"}>That need to connect, is what inspired
                            LIKA. With it’s name, meaning to
                            connect in Arabic, we created an all-in-one solution for social interaction aka social
                            planning.
                            From ideas to memories, we took an engineered approach to
                            help discover, organize, relive, sort and store our users’ experiences in one place.
                        </p>
                    </a.div>
                </div>
            </div>

            <div className="carousel-container w-full inline-flex flex-nowrap">
                <div
                    className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                    {carouselList}

                </div>
                <div
                    className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                    {carouselList}

                </div>
            </div>

            <div className={"ourmission-section section-padding"}>
                <div ref={ref2} className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <div>
                        <p className={"font-muli text-xsm pt-2 pb-2 uppercase text-gold"}>Our mission</p>
                        <div className={"medium-text-dev"}>
                            <a.p style={springsFadeIn} className={"font-rexton text-sm  text-white"}>To create a billion memories</a.p>
                        </div>
                    </div>
                    <div className={"mt-3"}>
                        <p className={"font-muli text-xsm pt-2 pb-2 uppercase text-gold"}>Our vision</p>
                        <div className={"small-text-dev"}>
                            <a.p style={springsFadeIn} className={"font-rexton text-sm  text-white "}>Connecting people through shared
                                Experiences</a.p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"fromthefounder-section"}>
                <div className="container mx-auto  flex flex-column justify-center relative">
                    <div className={"mx-auto w-full text-center flex flex-col md:flex-row p-5"}>
                        <div className={"card text-left flex flex-col justify-center items-start  basis-[45%]"}>
                            <img src={"img/team/jad_sleiman.png"} className={"w-full zoom-image"}/>
                        </div>
                        <div class="flex flex-row flex-wrap basis-[55%]">
                            <a.div ref={ref3} style={springs3} className={"flex flex-col text-left"}>
                                <p className={"font-muli text-xsm pt-2 pb-2 uppercase text-gold"}>from the founder</p>
                                <p className={"font-rexton text-basemd  text-white mb-3"}>JAD SLEIMAN</p>

                                <p className={"font-muli text-xsm  text-white"}>We all come into the world with the need to create, a need to give back to our
                                    society, communities and friends. I am an engineer by nature and as such, we seek to
                                    create a better world for our future.</p><br/>
                                <p className={"font-muli text-xsm  text-white"}>LIKA resonates deeply with me as I struggled to make plans while keeping up with the
                                    fast daily life in Dubai. I came to the realization that, in the end, life is
                                    defined by the memories we create—the joyous moments and experiences shared with our
                                    loved ones, immortalized in photos and videos.</p><br/>
                                <p className={"font-muli text-xsm  text-white"}>By simplifying the planning process, I aspire to contribute to a brighter future,
                                    one filled with cherished memories. A future that is not just lived but remembered.
                                </p><br/>
                                <p className={"font-muli text-xsm  text-white"}>Alexander Chalmers once said, “The three grand essentials of happiness are: Something
                                    to do, someone to love, and something to hope for.” I’m not promising happiness, but
                                    LIKA can provide you with something to do, something to hope for,
                                    and a reason to look forward to the future. Similar to how a seemingly insignificant
                                    plan led me to meet the love of my life—my beautiful wife—LIKA may play a role in
                                    helping you find someone to love or creating unexpected moments of joy.</p>
                            </a.div>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"likaforyou-section pt-20 pb-20"}>
                <div className={"text-center flex flex-col justify-center items-center"}>
                    <p className={"font-rexton text-base  text-white"}>Lika is FOR YOU</p>
                    <p className={"small-text-dev font-muli text-xsm mt-3 text-white"}>For all the places you want to go. For all the things you’d love to do, and for all the people you’d love to share those experiences with. </p>
                    <div className={"flex flex-row mt-3 "}>
                        <div><a target={"_blank"} href={process.env.REACT_APP_IOS_URL} ><img src={"img/download_app_store.png"} className={"p-2 w-full store-button"}/></a></div>
                        <div><a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL} ><img src={"img/download_google_play.png"} className={"p-2 w-full store-button"}/></a></div>
                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    )

}

export default About