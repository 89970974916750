import {createBrowserRouter, RouterProvider, Outlet, useParams} from "react-router-dom";
import Home from "./pages/Home/Home";

import "./app.scss"

import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";
import Terms from "./pages/Terms/Terms";
import Policy from "./pages/Policy/Policy";
import DataDeletion from "./pages/DataDeletion/DataDeletion";
import Download from "./pages/Download/Download";
import DownloadApp from "./pages/App/App";
import HomeBackup from "./pages/Home/Home-backup";


const Layout = () => {


    return (
        <div className="app">
            <div id="control-height"></div>
            <Navbar/>
            <Outlet/>
        </div>
    );
};

const RedirectHandler = ({baseUrl}) => {
    const {string} = useParams();

    // Redirect to the external URL
    window.location.href = `${baseUrl}${string}`;

    return null; // No need to render anything
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        // errorElement: <Error />,
        children: [
            {
                path: "/",
                element: <Home/>,
            },

            {
                path: "/home2",
                element: <Home/>,
            },
            {
                path: "/about",
                element: <About/>,
            },
            {
                path: "/partners",
                element: <Contact/>,
            },
            {
                path: "/contact",
                element: <Contact/>,
            },

            {
                path: "/invites/:string",
                element: <RedirectHandler
                    baseUrl="https://likasocial.go.link?adj_t=1e895thd&adj_deep_link=likaSocial%3A%2F%2F"/>,
            },
            {
                path: "/invites",
                element: <RedirectHandler
                    baseUrl="https://likasocial.go.link?adj_t=1e895thd&adj_deep_link=likaSocial%3A%2F%2F"/>,
            },
            {
                path: "/download",
                element: <Download/>,
            },
            {
                path: "/app",
                element: <DownloadApp/>,
            },
            {
                path: "/terms-conditions",
                element: <Terms/>,
            },
            {
                path: "/privacy-policy",
                element: <Policy/>,
            },
            {
                path: "/reset-password/:string",
                element: <RedirectHandler
                    baseUrl="https://likasocial.go.link?adj_t=1e895thd&adj_deep_link=likaSocial%3A%2F%2F"/>,
            },
            {
                path: "/data-deletion",
                element: <DataDeletion/>,
            }, {
                path: "*",
                element: <Home/>,
            }
        ],
    },
]);

function App() {

    return (
        <div>
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
