import React, {useCallback, useEffect, useState} from 'react'
import "./Download.scss"

import Footer from "../../components/Footer/Footer";

import 'alpinejs';

const Download = (props) => {

    useEffect(() => {

    }, []);

    return (
        <div className='download'>
            {/*<Loading/>*/}
            <div className={""}>
                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <p className={"font-rexton text-md pt-4 pb-0  uppercase text-white "}>Download </p>
                    <p className={"font-rexton text-md pb-4 pb-0 mb-5 uppercase text-white "}>Lika Social</p>
                    <div className={"flex flex-col"}>
                        <div>
                            <div className={"download-button"}><a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL}><img src={"img/google_download.png"} className={"w-100"}/></a></div>
                            <div className={"download-button mt-3"}><a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img src={"img/apple_download.png"} className={"w-100"}/></a></div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Download