import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Navbar.scss"
import $ from 'jquery';
import useFetch from "../../hooks/useFetch";

const Navbar = () => {
    const {hash, pathname, search} = window.location;
    const navlinks = [{"title": "HOME", "link": "/"},
        {"title": "ABOUT US", "link": "about"},
        {"title": "PARTNERS", "link": "partners"}]
    let hashName = hash.substring(1);


    React.useEffect(() => {
        var navbar = document.getElementById("navbar");
        var navbarJquery = $("#navbar");
        const toggleClass = "is-sticky";
        let lastScroll = 0;
        console.log("scroll to : " + hashName);
        window.addEventListener("load", () => {
            document.getElementById(hashName)?.scrollIntoView();
        })
        window.addEventListener("scroll", () => {
            const currentScroll = window.scrollY;

            // if (currentScroll > lastScroll && currentScroll > 150) {
            //     lastScroll = currentScroll;
            //     navbarJquery.fadeOut();
            // } else {
            //     navbarJquery.slideDown();
            //     lastScroll = currentScroll;
            // }

            if (currentScroll > 150) {

                navbar.classList.add(toggleClass);
            } else {
                navbar.classList.remove(toggleClass);
            }
        });

        const openBurgerMenuButton = document.getElementById("HAMBURGER-ICON");

        const dropdown = document.getElementById("dropdown");
        const links = document.querySelectorAll(".link");

        function openBurgerMenu() {
            openBurgerMenuButton.addEventListener("click", () => {
                closeBurgerMenu();
            });
            dropdown?.classList.remove("translate-x-full");
            dropdown?.classList.add("translate-x-0");
            for (const link of links) {
                link.classList.remove("translate-x-full");
                link.classList.add("translate-x-0");
            }
        }

        function closeBurgerMenu() {
            openBurgerMenuButton.addEventListener("click", () => {
                openBurgerMenu();
            });
            for (const link of links) {
                link.classList.remove("translate-x-0");
                link.classList.add("translate-x-full");
            }
            dropdown?.classList.remove("translate-x-0");
            dropdown?.classList.add("translate-x-full");
        }

        for (var index = 0; index < links.length; index++) {
            links[index].style.transitionDelay = index * 100 + "ms";
        }

        openBurgerMenuButton.addEventListener("click", () => {
            openBurgerMenu();
        });

        document.getElementById("close-dropdown-button").addEventListener("click", () => {
                closeBurgerMenu();
            });

    });


    return (
        <div className="navbar  " id={"navbar"}>
            <div className={"hidden lg:block"}>
                <div className="navbar-wrapper">
                    <div className="wrapper">

                        <div className="left">
                            <div className="item relative">
                                <Link to={"./"} onClick={() => window.location.href = "./"} style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px"
                                }}></Link>
                                <img src="img/lika_full_logo.png" alt=""/>
                            </div>
                        </div>


                        <div className="right">
                            <ul className="text-xxsm font-bold">
                                {(navlinks) ? (navlinks?.map((item) => (
                                    <li key={item.title}><a href={item.link}>{item.title}</a></li>))) : <li></li>}

                                {/*{error*/}
                                {/*    ? "Something went wrong!"*/}
                                {/*    : loading*/}
                                {/*        ? "loading"*/}
                                {/*        : data.attributes.navlinks?.map((item) => <li><a href={"#" + item.section_id}>{item.title}</a></li>)}*/}

                            </ul>
                            <a href={"download"}>
                                <button className="lika-button rounded-full text-xsm">
                                    SIGN UP
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block lg:hidden" id={"navbar"}>
                <div className="navbar-wrapper">
                    <div className="wrapper">

                        <div className="left">
                            <div className="item relative">
                                <Link to={"./"} onClick={() => window.location.href = "./"} style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px"
                                }}></Link>
                                <img className={"object-contain"} src="img/lika_full_logo.png" alt=""/>
                            </div>
                        </div>


                        <div className="right">
                            <div id={"HAMBURGER-ICON"}
                                className="HAMBURGER-ICON space-y-2"
                            >
                                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                            </div>

                        </div>
                        <div id="dropdown" className="fixed top-0 right-0 text-white w-96 z-50 h-full translate-x-full transition-all duration-500 ease-in-out">
                            <div className="px-2 pt-2 flex items-center justify-between">
                                <button
                                    className="w-16 h-16 rounded-full grid place-content-center ml-auto"
                                    id="close-dropdown-button">
                                    <span className="block text-base font-extralight">✕</span>
                                </button>
                            </div>
                            <h2 className="opacity-60 text-xs uppercase ml-6">Navigation</h2>
                            <div className="h-[1px] w-full bg-gray-500 mt-4"></div>
                            <div className="px-6" id="links">

                                <ul className="flex flex-col text-xxsm font-bold">
                                {(navlinks) ? (navlinks?.map((item) => (
                                    <li key={item.title} className={"block"}>
                                        <a className="py-4 text-base block transition-transform duration-500 ease-in-out translate-x-full link font-bold"
                                                            id={item.title}
                                                            href={item.link}>{item.title}
                                    </a></li>))) : <li></li>}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Navbar;
