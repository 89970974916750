import React, {useCallback, useEffect, useState} from 'react'
import "./Policy.scss"
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";

const Policy = (props) => {

    // const [faq, setFAQ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    return (
        <div className='policy'>

            <Loading/>
            <div className={"header-section section-padding"}>
                <p className={"font-rexton pt-2 pb-2 uppercase"}>Privacy Policy</p>
            </div>

            <div className={"container section-padding"}>
                <p><strong><span>Effective Date</span></strong><span>: <span>10<sup>th</sup> Feb 2024</span></span></p>
                <br/>
                <p><strong>LIKA Social FZ-LLC</strong>, a free zone limited liability company registered at the Dubai Development Authority in Dubai, United Arab Emirates, under commercial license number 101791 (the "<strong>Company</strong>", &ldquo;<strong>Lika</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;, or &ldquo;<strong>our</strong>), is the owner and operator of the Lika Social website (<a href="http://www.likasocial.com">www.likasocial.com</a> ) and of the LIKA mobile application (together referred to as the "<strong>Platform</strong>").</p>
                <p>This page informs you and the other users of the Platform (hereinafter referred to as the &ldquo;<strong>User</strong>&rdquo;, &ldquo;<strong>Users</strong>&rdquo;, &ldquo;<strong>you&rdquo;</strong>, <strong>&ldquo;your&rdquo;</strong>, or<strong> &ldquo;yours</strong>&rdquo;) of our policies regarding the collection, use, and disclosure of personal data when you use our Platform and the choices you have associated with that data.</p>
                <p>By using the Platform, you agree to the collection and use of your information in accordance with this policy. Unless otherwise defined in this Privacy Policy (the &ldquo;<strong>Privacy Policy</strong>&rdquo;), the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                <p>By accessing the Platform,&nbsp;you agree to the terms of this Privacy Policy.</p>
                <ol>
                    <li><strong> </strong>We respect your privacy.</li>
                    <li><strong> </strong>Security is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your information, but we cannot guarantee its absolute security, nor can guarantee against the unauthorized disclosure, alteration, or destruction of your information</li>
                    <li><strong> </strong>We collect information you give us when you register or use our online forms. We track the web pages you visit through the use of cookies.</li>
                    <li><strong> </strong>We may use your information to improve the performance and accuracy of our Platform and system. We will only do so if we can preserve the confidentiality of user identifiable information.</li>
                    <li><strong> </strong>If you choose to post publicly on our blog or otherwise explicitly give us permission to share your identifiable information with others, this information may become public.</li>
                    <li><strong> </strong>You can opt out of marketing communications we send.</li>
                    <li><strong> </strong>We may link to other websites. We cannot vouch for their privacy policies.</li>
                    <li><strong> </strong>We will post updates to our privacy policy on our Platform.</li>
                    <li><strong> </strong>Use of the Platform is subject to our Terms and Conditions, which are available at https://likasocial.com/terms-conditions</li>
                    <li><strong> </strong>Please contact us if you would like to learn more about our company and our policies.</li>
                </ol>
                <p>&nbsp;</p>
                <ol start="11">
                    <li><strong> </strong><strong>Definitions</strong>.</li>
                </ol>
                <p><strong>&ldquo;User&rdquo; or &ldquo;Users&rdquo; </strong>means any user of the Platform, whether it be browsers, vendors, customers, merchants, and/or contributors of content, the account holder, or anyone using the Platform or any part thereof, be it a person, company, entity, etc.</p>
                <p>"<strong>Effective Date</strong>" is defined in the introduction to this Privacy Policy.</p>
                <p>"<strong>Information</strong>" is defined in section&nbsp;12 (Information Collection)<strong>.</strong></p>
                <p>"<strong>Personally Identifiable Information</strong>" is defined in section&nbsp;12 (Information Collection).</p>
                <ol start="12">
                    <li><strong> </strong><strong>Information Collection.</strong>
                        <ul>
                            <li><strong> </strong><strong>Information Lika</strong> <strong>Collects</strong>. Lika may collect information that identifies Users when accessing the Platform (collectively, "<strong>Information</strong>"), which includes:
                                <ol>
                                    <li>User&rsquo;s name, addresses, contact list, device location, phone numbers, birth dates, photos &amp; videos, gender, billing and delivery information, email addresses, activity on the platform, payment method chosen for a specific transaction on the platform, and other financial account information (hereinafter referred to as "<strong>Personally Identifiable Information</strong>"),</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>
                <p>The User&rsquo;s device location data and geolocation shall be collected by Lika when the Platform is running in the foreground (app open and on-screen) or background (app open but not on-screen) of the User&rsquo;s mobile device,</p>
                <ol>
                    <li>other information Users provide to Lika but that does not identify Users personally, such as records of User&nbsp;visits, and information Users submit when using the Platform,&nbsp;</li>
                    <li>content that the Users uploaded to the Platform including videos, voice recordings, etc., and other information the Users choose to provide,</li>
                    <li>information from third parties, and</li>
                    <li>traffic and usage information generated from User&nbsp;visits to the Platform, including traffic data and the type of device (hardware and software) that Users use to&nbsp;access the Platform.</li>
                </ol>
                <ul>
                    <li><strong> </strong><strong>Tracking Technologies Lika</strong> <strong>Uses</strong>. Lika may use a variety of technologies to collect Information, including cookies, flash cookies, general log information, and referral information from third-party websites.</li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Retention of Information.</strong>
                        <ul>
                            <li>Should you have created an account on the Platform, you specifically acknowledge and agree to the fact that Lika will retain your Information for as long as your account on the Platform remains active to enable us to provide you with our Services. Deleting the mobile application on your mobile phone is not sufficient to delete your account with us. To delete the account, please refer to section 18 below. After deletion of your account, your Information shall be anonymized, and your Personally Identifiable Information shall be deleted from our data repository to prevent your identification. In any case, you agree that Lika will retain any of your Information necessary for tax and accounting purposes for a period of five (5) years from the time where such Information was generated during your use of our Platform.</li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li>Should you have accessed the Platform without creating an account, you specifically acknowledge and agree to the fact that Lika will retain your Information for the length of time needed to fulfil the purposes for which it was collected, unless Lika specifically agree a longer retention period with you, or a longer retention period is required or permitted by law. In any case, you agree that Lika will retain any of your Information necessary for tax and accounting purposes for a period of five (5) years from the time where such Information was generated during your use of our Platform. After this period of time, your Information shall be anonymized or deleted from our data repository.</li>
                </ul>
                <ol start="13">
                    <li><strong> </strong><strong>No Information Collected from Children</strong>
                        <ul>
                            <li><strong> </strong><strong>Platform</strong> <strong>Not Intended for Children</strong>. Lika does not intend that the&nbsp;Platform or&nbsp;its content are used&nbsp;by children under the age of 18.</li>
                        </ul>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>No Information Intentionally Collected from</strong><strong>Children</strong>.<strong></strong>Lika will&nbsp;not, without parental consent, intentionally:
                        <ol>
                            <li><strong> </strong>collect or solicit any Information from anyone under the age of 18, or</li>
                            <li><strong> </strong>allow people under the age of 18 to register for the Platform.</li>
                        </ol>
                    </li>
                    <li><strong> </strong><strong>In Case of Unintended Collection</strong>.&nbsp;If Lika learns from any person that it&nbsp;has collected Information&nbsp;from a child under age 18 without parental consent, Lika shall promptly&nbsp;delete that Information.</li>
                </ul>
                <ol start="14">
                    <li><strong> </strong><strong>How Lika</strong> <strong>Uses Information</strong>. Lika may use the Information it collects to:
                        <ul>
                            <li><strong> </strong>provide, operate, maintain, improve, and promote, the Platform,</li>
                            <li><strong> </strong>enable Users&nbsp;to access and use the Platform, including uploading, downloading, collaborating on, and sharing content,</li>
                            <li><strong> </strong>allow the Users to participate in interactive features of our Platform when they choose to do so,</li>
                            <li><strong> </strong>process and complete transactions, and send Users&nbsp;related information, including purchase and other transactions confirmations and invoices,</li>
                            <li><strong> </strong>send transactional messages, including responding to User&nbsp;comments, questions, and requests, providing User&nbsp;service and support, and sending Users&nbsp;technical notices, updates, security alerts, and support and administrative messages,</li>
                            <li><strong> </strong>send promotional communications, such as providing Users&nbsp;with information about services, features, newsletters, offers, promotions, contests, events, and sending updates about User&nbsp;team and chat rooms, if the User opted-in to receiving such marketing communications,</li>
                            <li><strong> </strong>monitor and analyze trends, usage, and activities in connection with the Platform and for marketing or advertising purposes,</li>
                            <li><strong> </strong>provide customer service, care and support,</li>
                            <li><strong> </strong>investigate and prevent fraudulent transactions, unauthorized access to the Platform, and other illegal activities,</li>
                            <li><strong> </strong>detect, prevent and address technical issues,</li>
                            <li><strong> </strong>personalize the Platform content, features, advertisements, or promotions, in light of the User(s)&rsquo; Information, and</li>
                            <li><strong> </strong>enable Users&nbsp;to share content with other Users&nbsp;they designate.</li>
                        </ul>
                    </li>
                    <li><strong> </strong><strong>Information Sharing</strong>
                        <ul>
                            <li><strong> </strong><strong>Advertising and Cookies</strong>
                                <ul>
                                    <li><strong> </strong><strong>Third Party Advertising and Cookies</strong>.&nbsp;Lika may permit third party advertising partners to deliver ads to Users and use cookies on the Platform.</li>
                                    <li><strong> </strong><strong>Policy Does Not Cover Third Party Advertisers</strong>.&nbsp;This policy does not cover third party advertising partners' use of cookies, which is&nbsp;subject to the third party advertising partners' own privacy policies.</li>
                                    <li><strong> </strong><strong>Use of Cookies</strong>. Lika may, and may permit third party advertising partners, to use cookies to:
                                        <ul>
                                            <li><strong></strong>recognize a User's computers,</li>
                                            <li><strong></strong>send online advertisements to User, if the User opted-in to receiving online advertisements, and&nbsp;</li>
                                            <li><strong></strong>compile Information about a User's, and other's, use of its computer.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Merchants/Vendors. </strong>Lika will share Information with&nbsp;merchants or vendors providing items, merchandise and/or services to the Users to facilitate their operation, subject to the terms of this policy, and only to the extent necessary:
                        <ul>
                            <li><strong> </strong>To provide items, merchandise, perform functions and/or provide services to the Users, including but not limited to choosing the items or services you ordered through the Platform, delivering such items or services to you, and calling you or texting you with any updates or issues,</li>
                            <li><strong> </strong>to process payments,</li>
                            <li><strong> </strong>to carry out marketing and/or advertising activities if you opted-in to receiving marketing or advertisement communications,</li>
                            <li><strong> </strong>to prevent, detect, and investigate fraud or other prohibited activities, and</li>
                            <li><strong> </strong>to facilitate dispute resolution, such as chargebacks or refunds.</li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Service Providers</strong>. Lika may share Information with&nbsp;third party service providers it employs to perform functions and provide services for Lika and the Platform, subject to the terms of this policy, and only to the extent necessary:
                        <ul>
                            <li><strong> </strong>to perform functions and provide services for Lika and/or on Lika&rsquo;s behalf,</li>
                            <li><strong> </strong>to assist us in analyzing how our Platform is used,</li>
                            <li><strong> </strong>to process payments,</li>
                            <li><strong> </strong>to prevent, detect, and investigate fraud or other prohibited activities,</li>
                            <li><strong> </strong>to facilitate dispute resolution, such as chargebacks or refunds, and</li>
                            <li><strong> </strong>for other purposes associated with the acceptance of credit or debit cards.</li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Others</strong>. Lika will share your Information with entities outside of Lika:
                        <ul>
                            <li><strong> </strong>when we have your consent to do so or when it is done at your direction,</li>
                            <li><strong> </strong>on a non-personal or aggregate basis: we share Information with merchants/vendors and advertisers on both a non-personally identifying basis (including order and delivery details but not including Personally Identifiable Information) and an aggregate basis.</li>
                            <li><strong> </strong>for business purposes: we may share your Information in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company. We may also share your Information between and among Lika, and its current and future parents, affiliates, subsidiaries, and other companies under common control and ownership.</li>
                        </ul>
                    </li>
                </ul>
                <ol start="16">
                    <li><strong> </strong><strong>User Settings</strong>. Lika shall provide Users&nbsp;the ability to exercise certain controls and choices regarding Lika&rsquo;s collection, use, and sharing of User&nbsp;information, to the extent required by law, which might include a User's ability to:
                        <ol>
                            <li><strong> </strong>correct, update, and delete account information,</li>
                            <li><strong> </strong>change choices for subscriptions and newsletters,</li>
                            <li><strong> </strong>choose whether or not to receive marketing content and offers from Lika, and/or from the merchants, and/or from third party advertising partners, and</li>
                            <li><strong> </strong>choose whether the User&nbsp;receives targeted advertising from Lika, the merchants/vendors, or third party advertising&nbsp;partners.</li>
                        </ol>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ol start="17">
                    <li><strong> </strong><strong>Accessing and Correcting User&nbsp;Information</strong>
                        <ul>
                            <li><strong> </strong><strong>Right to Access your Information. </strong>At any time, you have the right to access your Information by sending an email to Lika. Should you wish to obtain a copy of your Information, Lika will send you a copy of your Information.</li>
                        </ul>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Right to Correct Contact Information and Preferences</strong>.&nbsp;Lika is not responsible for the accuracy of the Information Users provide. Lika shall provide Users with the opportunity to ensure that contact information and preferences are accurate, complete, and up to date, through each User's account, accessible from&nbsp;the settings page of the LIKA app.</li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Opportunity to Request Corrections and Deletions</strong>. Subject to paragraph 17.5 below, Lika shall provide Users with the opportunity to request that we:
                        <ul>
                            <li><strong> </strong>correct inaccurate Personally Identifiable Information that we hold, and</li>
                            <li><strong> </strong>delete Information that we are not required to retain by law or for legitimate business purposes.</li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Corrections and Deletions Process. </strong>
                        <ul>
                            <li><strong> </strong>In the event the Information subject of the request of correction has been sent to a third party, Lika shall notify the latter of the amendments made at your request.</li>
                            <li><strong> </strong>Subject to paragraph 17.5 below, Lika shall correct or delete the Information requested to be deleted or corrected within ten (10) days from the date when it is informed of one of the reasons requiring it to correct or cancel the said Information.</li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong><strong>Discretion Over Corrections and Deletions</strong>.&nbsp;Lika will have the sole discretion&nbsp;to adhere to or decline User requests to correct or delete Information that are&nbsp;frivolous or vexatious, jeopardize the privacy of others, are extremely impractical, or not otherwise required by law.</li>
                </ul>
                <p>&nbsp;</p>
                <ol start="18">
                    <li><strong> </strong><strong>Deleting/Terminating Your Account on the Platform</strong></li>
                </ol>
                <p>Users are able to delete/terminate their account on the Platform, provided that you do not owe us any unpaid amount, by contacting us by email and asking us to delete/terminate your account on the Platform.</p>
                <ol start="19">
                    <li><strong> </strong><strong>No Security Guarantees</strong>. Lika does not guarantee against all unauthorized disclosure, alteration, or destruction of Information.</li>
                </ol>
                <p>&nbsp;</p>
                <ol start="20">
                    <li><strong> </strong><strong>Legally Required Disclosure of Information. </strong>Lika may disclose your Information in the good faith belief that such action is necessary to:
                        <ol>
                            <li><strong> </strong>comply with a legal obligation;</li>
                            <li><strong> </strong>enforce or apply our Terms and Conditions, investigate potential breaches or if we believe your actions are inconsistent with our Terms and Conditions, or protect and defend the rights, property, or safety of Lika or others;</li>
                            <li><strong> </strong>prevent or investigate possible wrongdoing in connection with the Platform;</li>
                            <li><strong> </strong>protect the personal safety of the Users or the public; or</li>
                            <li><strong> </strong>protect Lika against legal liability.</li>
                        </ol>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ol start="21">
                    <li><strong> </strong><strong>Your Rights under the United Arab Emirates Federal Decree No. 45 of 2021 regarding the protection of personal data (&ldquo;PPD&rdquo;). </strong>The User&rsquo;s principal rights under the PPD are as follows:
                        <ol>
                            <li><strong> </strong><strong>Right of Access</strong>: you have the right to be informed of, and request access to, the Information we process about you.</li>
                            <li><strong> </strong><strong>Right to Rectification</strong>: you have the right to request that we amend or update your personal data where it is inaccurate or incomplete.</li>
                            <li><strong> </strong><strong>Right to Erasure</strong>: you the right to have Lika delete your Information.</li>
                            <li><strong> </strong><strong>Right to Restrict</strong>: you have the right to request that we temporarily or permanently stop processing your Information.</li>
                            <li><strong> </strong><strong>Right to be Informed</strong>: you have the right to request clarification as to the reasons why your Information is being collected, and the identity of the recipients to whom your Information has been disclosed.</li>
                            <li><strong> </strong><strong>Right to Object</strong>: you have the right to object to us processing your Information on grounds relating to your particular situation or for direct marketing purposes.</li>
                            <li><strong> </strong><strong>Right to Data Portability</strong>: you have the right to request a copy of your Information in electronic format and the right to transmit that personal data for use in another party&rsquo;s service.</li>
                            <li><strong> </strong><strong>Right not to be Subject to Automated Decision Making</strong>: you have the right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.</li>
                        </ol>
                    </li>
                </ol>
                <p>Lika endeavors to apply your rights following any justified request on your part.</p>
                <ol start="22">
                    <li><strong> </strong><strong>Changes to Policy</strong>
                        <ul>
                            <li><strong> </strong><strong>Discretion to Make Changes</strong>.&nbsp;Lika may change this policy at its discretion.&nbsp;</li>
                            <li><strong> </strong><strong>Notice of Changes</strong>.&nbsp;Lika shall provide reasonable notice of any such changes on the Platform.</li>
                            <li><strong> </strong><strong>Acceptance of</strong><strong>Changes</strong>.<strong></strong>Users' continued use of the Platform after notice of the changes has been posted to the Platform will be deemed acceptance of the changes, if
                                <ul>
                                    <li><strong> </strong>a reasonable time has passed in which Users could review the changes, but</li>
                                    <li><strong> </strong>regardless as to whether the User actually did review the changes.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ol>
                <ol start="23">
                    <li><strong> </strong><strong>Links to Other Websites</strong>. Lika will not be responsible for the content on or privacy practices of third party sites linked to from the Platform.</li>
                </ol>
                <p>&nbsp;</p>
                <ol start="24">
                    <li><strong> </strong><strong>Storage and Transfer of Information.</strong>
                        <ul>
                            <li><strong> </strong>You agree that we have the right to transfer your Information described in this Privacy Policy, including <strong>Personally Identifiable Information</strong>, to and from, and process and store it in UAE, Germany, and other countries, some of which may have less protective data protection laws than the region in which you reside. Where this is the case, we will take appropriate measures to protect your Information in accordance with this Privacy Policy By submitting your Information, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure your data is treated securely and in accordance with this Privacy Policy.</li>
                        </ul>
                    </li>
                </ol>
                <p>&nbsp;</p>
                <ul>
                    <li><strong> </strong>To preserve the integrity of our databases, to carry out on-going services on your behalf, for research, analytics and statistics purposes and to ensure compliance with applicable laws and regulations, we retain the Information submitted by Users for a reasonable length of time. By accessing or using the Services, you do hereby represent and warrant that you understand that all information submitted by you through the Platform or otherwise to Lika may be used by Lika in accordance with applicable laws, this Privacy Policy and our Terms and Condition.</li>
                </ul>
                <ol start="25">
                    <li><strong> </strong><strong> </strong>Users may&nbsp;contact Lika by email at <a href="mailto:info@likasocial.com">info@likasocial.com</a></li>
                </ol>
            </div>

            <Footer/>

        </div>
    )

}

export default Policy