import React, {useCallback, useEffect, useState} from 'react'
import "./Terms.scss"
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";

const Terms = (props) => {

    // const [faq, setFAQ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    return (
        <div className='terms'>

            <Loading/>
            <div className={"header-section section-padding"}>
                <p className={"font-rexton pt-2 pb-2 uppercase"}>Terms & Conditions</p>
            </div>

            <div className={"container section-padding"}>

                <p>Terms and Conditions<br />
                    Effective Date: 10th Feb 2024<br /></p>
                    <b>Acceptance of the Terms and Conditions.</b> This is an agreement between LIKA Social FZ-LLC, a free zone limited liability company registered at the Dubai Development Authority in Dubai, United Arab Emirates, under commercial license number 101791 (&quot;Lika&quot;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;), the owner and operator of the Lika Social website (www.likasocial.com ) and of the Lika mobile application (together the &ldquo;Platform&rdquo;), and you (&quot;you&quot;, &quot;You&quot;, &ldquo;your&rdquo; or &ldquo;Your&rdquo;), a user of the Platform. By using the Platform, you acknowledge and agree to these Terms and Conditions (the &ldquo;Terms&rdquo;) and also the Privacy Policy, which can be found <a href={"privacy-policy"}>here</a> and is incorporated by reference. If you choose to not agree with any of these Terms, you may not use the Platform.<br />
                    <ol style={{listStyleType: "lower-alpha"}}>

                    <li>Changes to Terms and Conditions.</li>
                    <ol >
                        <li>Right to Change Terms. Lika reserves the right, in its sole discretion, to change these Terms (&quot;Updated Terms&quot;) from time to time.</li>
                        <li>Notice of Updated Terms. Unless Lika makes a change for legal or administrative reasons, Lika will provide reasonable advance notice before the Updated Terms become effective. You agree that Lika may notify you of the Updated Terms by posting them on the Platform.</li>
                        <li>Acceptance of Updated Terms. Your use of the Platform after the effective date of the Updated Terms constitutes your agreement to the Updated Terms. You should review these Terms and any Updated Terms before using the Platform.</li>
                        <li>Effective Date of Updated Terms. The Updated Terms will be effective as of the time of posting, or such later date as may be specified in the Updated Terms, and will apply to your use of the Platform from that point forward.</li>
                    </ol>
                    </ol>

                    2. Your Account.<br />
                    2.1 Account Creation. You must complete the registration process by providing Lika with current, complete, and accurate information as prompted by the applicable registration form. You also will choose a password and a username.

                <p>2.2 Responsibility for Account. You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify Lika immediately of any unauthorized use of your account or any other breach of security.</p>

                <p>2.3 Liability for Account Misuse. Lika will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. You could be held liable for losses incurred by Lika or another party due to someone else using your account or password.</p>

                <p>2.4 Use of Other Accounts. You may not use anyone else's account at any time, without the permission of the account holder.</p>

                <p>2.5 Closure of the Account. You may close your account at any time by requesting to do so in your Account section of the Platform or contacting Lika on the email [CUSTOMER CARE EMAIL].<br />
                    Lika may, at any time without notice, and&#160;at our sole discretion, withdraw your account and suspend, restrict or withdraw your access to the Platform, including if we cease the operation of the Platform, or if we believe that your account has been used by someone else or if in our opinion, you are abusing the use of the Platform or if you fail to comply with one or more terms of these Terms.<br />
                    3. Account Security. Lika cares about the integrity and security of your personal information. However, Lika cannot guarantee that unauthorized third parties will never be able to defeat the Platform&rsquo;s security measures or use any personal information you provide to us for improper purposes. You acknowledge that you provide your personal information at your own risk.<br />
                    4. License. During the term of this agreement, Lika grants you a limited, non-exclusive, non-transferable license to access the Platform for your personal and non-commercial [YB1]use in accordance with the Terms.<br />
                    5. Paid Services.<br />
                    5.1 Fees. Lika may&#160;at any time charge fees for access to the Platform. However, in no event will you be charged for access to the Platform without&#160;your prior agreement, and you may cancel your account at any time. Any fees will be posted prominently on the Platform and in other appropriate locations on the Platform.</p>

                <p>5.2 Rates. You will pay all fees and charges incurred through your account at the rates in effect for the billing period when they are incurred. You will be billed for and pay all fees and charges, along with all applicable taxes, relating to your use of the Platform through your account.<br />
                    6. Intellectual Property Rights. The design, trademarks, service marks, and logos of the Platform (&quot;Marks&quot;), and the content displayed on the Platform (the &ldquo;Content&rdquo;) are owned by or licensed to Lika subject to copyright and other intellectual property rights under United Arab Emirates (&ldquo;UAE&rdquo;) and foreign laws and international conventions. Lika reserves all rights not expressly granted in and to the Platform. You agree to not engage in the use, copying, or distribution of any of the Platform other than expressly permitted.<br />
                    7. User Conduct. You may not use the Platform to engage in any of the following prohibited activities:<br />
                    7.1 copying, distributing, or disclosing any part of the Platform in any medium, including without limitation by any automated or non-automated &quot;scraping&quot;, or to spam, phish, pharm, pretext, spider, or crawl on or through the Platform;</p>

                <p>7.2 using any automated system, including without limitation &quot;robots,&quot; &quot;spiders,&quot; &quot;offline readers,&quot; etc., to access the Platform;</p>

                <p>7.3 transmitting spam, chain letters, or other unsolicited email;</p>

                <p>7.4 any unlawful purpose;</p>

                <p>7.5 posting inappropriate content or using the Platform inappropriately (as determined by Lika at its sole discretion) such as but not limited to: political or controversial events or posts or use, sexual or explicit or obscene posts or events or use, etc.</p>

                <p>7.6 attempting to interfere with, compromise the system integrity or security of, or decipher any transmissions to or from the servers running the Platform;</p>

                <p>7.7 taking any action that imposes or may impose, at our sole discretion, an unreasonable or disproportionately large load on the Platform infrastructure;</p>

                <p>7.8 uploading invalid data, viruses, worms, or other software agents, or any other type of malicious code on or through the Platform, that will or may affect the functionality or operation of the Platform or of any related website, or third party websites, or the Internet;</p>

                <p>7.9 collecting or harvesting any personally identifiable information, including account names, from the Platform;</p>

                <p>7.10 using the Platform for any commercial solicitation purposes;[YB2]</p>

                <p>7.11 impersonating another person or otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity;</p>

                <p>7.12 interfering with the proper working of the Platform;</p>

                <p>7.13 accessing any Content on the Platform through any technology or means other than those provided or authorized by the Platform;</p>

                <p>7.14 infringing upon or violating our intellectual property rights or the intellectual property rights of others;</p>

                <p>7.15 to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</p>

                <p>7.16 submitting false or misleading information; or<br />
                    7.17 bypassing the measures we may use to prevent or restrict access to the Platform, including without limitation, features that prevent or restrict use or copying of any Content or enforce limitations on use of the Platform or the Content.<br />
                    8. User Content.<br />
                    8.1 Content Ownership. You retain all ownership rights to content that you uploaded to the Platform.</p>

                <p>8.2 Content License. By submitting content to the Platform, you grant Lika a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the Platform and Lika&rsquo;s (and its successors' and affiliates') business, including without limitation for promoting and redistributing part or all of the Platform (and derivative works thereof) in any media formats and through any media channels.<br />
                    9. Third Party Content. Through the Platform, you will have the ability to access and/or use content provided by third parties. Lika cannot guarantee that such third party content will be free of material you may find objectionable or otherwise. Lika disclaims any responsibility or liability related to your access or use of any third party content.<br />
                    10. Booking Events Through Links to other Websites. You may book services or tickets through third party links included in the Platform. <br />
                    11. Links to other Websites.<br />
                    11.1 Links. The Platform may contain links to third-party websites or resources. You acknowledge and agree that Lika is not responsible or liable for:<br />
                    a) the availability or accuracy of such websites or resources; or<br />
                    b) the content, products, or services on or available from such websites or resources.<br />
                    14.1 No Endorsement. Links to such websites or resources do not imply any endorsement by Lika of those websites or resources.</p>

                <p>14.2 Assumption of Risk. You acknowledge sole responsibility for and assumes all risk arising from your use of any such websites or resources.<br />
                    12. Privacy.&#160;For information about how Lika collects, uses, and shares your information, please review our Privacy Policy at [PRIVACY POLICY URL]. You agree that, by using the Platform, you consent to the collection, use, and sharing (as set forth in the Privacy Policy) of&#160;that&#160;information (including the transfer of this information to Lebanon, Germany or other countries for Lika&rsquo;s storage, processing, and use).<br />
                    13. Copyright Policy. Lika respects the intellectual property rights of others and expects users of the Platform to do the same. We will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us. If you believe that your content has been copied in a way that constitutes copyright infringement, please provide our copyright agent with the following information:<br />
                    a) a physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</p>

                <p>b) identification of the copyrighted work claimed to have been infringed;</p>

                <p>c) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</p>

                <p>d) your contact information, including your address, telephone number, and an email address;</p>

                <p>e) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law, and</p>

                <p>f) a statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.<br />
                    14. Promotions. Some users may promote competitions, promotions, prize draws, and other similar opportunities on the Platform (&quot;Third Party Competitions&quot;). Lika is not the sponsor or promoter of these Third Party Competitions and does not bear any responsibility or liability for the actions or inactions of any third parties who organize, administer, or are otherwise involved in any of promotion of these Third Party Competitions. If you wish to participate in any of these Third Party Competitions, you are responsible for reading and ensuring that you understand the applicable rules and any eligibility requirements, and are lawfully able to participate in such Third Party Competitions in your country of residence. If you wish to run your own Third Party Competition on the Platform, you are free to do so provided you obtain our prior written approval and comply with our Terms.<br />
                    15. Suspension and Termination of Access.<br />
                    15.1 Lika may suspend or terminate your access to the Platform immediately without prior notice and without further obligation or liability to you if, in Lika&rsquo;s sole discretion, it determines that you have breached or failed to comply with any provision(s) of the Terms. Lika may also suspend your access to the Platform or terminate the Terms at any time without written notice to you&#160;for any reason whatsoever.&#160;</p>

                <p>15.2 If the Terms are terminated for any reason, you are still bound by the Terms as set out herein. To the extent permitted by applicable law, the disclaimers, limitations on liability, termination, interpretative provisions, and your warranties and indemnities shall survive any termination of the Terms.</p>

                <p>15.3 Lika has no liability of any kind or any nature whatsoever to you solely by reason of any change, modification, suspension or discontinuance of the Platform in accordance with the Terms. You agree that you shall not make any claim against Lika, including with respect to any lost revenue, profits or opportunities as a result of such change, modification, suspension or discontinuance or on account of any expenditures made or actions taken in reliance on the expected continuation of the Platform or the Terms.</p>

                <p>15.4 Lika shall not be required to refund to you any amounts prepaid, if any, for use of the Platform if Lika terminated your account or your use of, or access to, the Platform for breach of the Terms. If Lika terminated your access to the Platform without breach on your part, any fees or charges paid by you in advance, if any, shall be refunded on a&#160;pro rata&#160;basis by applying a credit to your registered credit card, or if that is not possible for any reason, by way of a bank transfer using bank details that you have provided to us.<br />
                    16. Disclaimers.&#160;The Platform is provided &quot;as is,&quot; without any warranties of any kind. To the fullest extent permissible under applicable law, Lika disclaims all such warranties, express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, accuracy, freedom from errors, suitability of content, or availability.<br />
                    17. Limitation on Liability.<br />
                    17.1 Lika will not be responsible or liable for any loss or damages of any kind including but not limited to direct, indirect, incidental punitive and consequential damages, injuries which as a result of your use of the Platform or your purchase services purchased through the Platform, whether in contract (including negligence) or otherwise, even if foreseeable, arising under or in connection with:<br />
                    17.1.1 use of, or inability to use, the Platform including any tools used on or otherwise linked or integrated with the Platform; or<br />
                    17.1.2 use of or reliance on any content displayed on the Platform, including any content displayed on any tools used on or otherwise linked or integrated with the Platform; or<br />
                    17.1.3 use of or reliance on any content of websites linked to the Platform.</p>

                <p>17.2 We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Platform or your downloading of any content on it, or on any website linked to it.</p>

                <p>17.3 We assume no responsibility for the content of websites linked to the Platform. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>

                <p>17.4 We do not guarantee, represent or warrant that your use of the Platform will be uninterrupted, timely, secure or error-free.</p>

                <p>17.5 You agree that from time to time we may remove the Platform for indefinite periods of time or cancel the Platform at any time, without notice to you.</p>

                <p>17.6 You expressly agree that your use of, or inability to use, the Platform is at your sole risk.</p>

                <p>17.7 You expressly agree that your use of, or inability to use, the Platform is at your sole risk. The Platform and all products and services delivered to you through the Platform are (except as expressly stated by us) provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo; for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>

                <p>17.8 In no case shall we, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Platform or any service or item procured using the Platform, or for any other claim related in any way to your use of the Platform or any service or item including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Platform (or any content or service or item) posted, transmitted, or otherwise made available via the Platform, even if advised of their possibility.</p>

                <p>17.9 Neither party will be liable for breach-of-contract damages that are remote or speculative, or that the breaching party could not reasonably have foreseen on entry into this agreement.<br />
                    18. &#160;Indemnification.&#160;You&#160;shall indemnify, defend and hold harmless&#160;Lika and its collaborators, suppliers and licensors, and their officers, directors, agents, and employees from and against any claim, proceeding, loss, damage, fine, penalty, interest, and expense (including, without limitation, fees for attorneys and other professional advisors) made by any third party due to or arising out of or in connection with<br />
                    a) your use of the Platform;<br />
                    b) your breach of this agreement;<br />
                    c) violation of law;<br />
                    d) your submission,&#160;posting,&#160;or transmission of user content to the Platform; or<br />
                    e) violation of the rights of a third party.<br />
                    19. Dispute Resolution.<br />
                    19.1 Informal Dispute Resolution. The parties shall cooperate to&#160;attempt to informally resolve any&#160;dispute arising out of this agreement before submitting the dispute to the competent courts.</p>

                <p>19.2 Competent Court. In the event of failure of such negotiations, the courts of Dubai, United Arab Emirates, shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these Terms.<br />
                    20. Feedback.&#160;We welcome any comment, question,&#160;and communication at [FEEDBACK LINK].<br />
                    21. General Provisions.<br />
                    21.1 Governing Law. This agreement and these Terms will be governed, construed, and enforced in accordance with the laws of Dubai in the United Arab Emirates without regard to its conflict of laws rules.</p>

                <p>21.2 Assignment. You may not assign this&#160;agreement and/or these Terms and/or any of your rights or obligations under this&#160;agreement and/or these Terms&#160;to any other party without our prior&#160;written consent. We may assign the rights and obligations under this agreement and/or these Terms to any other entity.</p>

                <p>21.3 Notices.</p>

                <p>21.3.1 Method of Notice. The parties will give all notices and communications between the parties in writing by (i) personal delivery, (ii) a nationally recognized, next-day courier service, (iii) first-class registered or certified mail, postage prepaid, or email to the address that a party has notified to be that party's address for the purposes of this section.<br />
                    21.3.2 Receipt of Notice. A notice given under&#160;this&#160;agreement&#160;will be effective on:</p>

                <p>21.3.2.1 the other party's receipt of&#160;it, or<br />
                    21.3.2.2 if mailed, the earlier of the other party's receipt of it and the fifth business day after mailing it.&#160;</p>

                <p>21.4 Waiver</p>

                <p>21.4.1 Affirmative Waivers. Neither party's failure or neglect to enforce any rights under this agreement will be deemed to be a waiver of that party's rights.<br />
                    21.4.2 Written Waivers. A waiver or extension&#160;is only effective if&#160;it is in writing and signed by the party granting it.<br />
                    21.4.3 No General Waivers. A party's failure or neglect to enforce any of its rights under this agreement will not be deemed to be a waiver of that or any other of its rights.<br />
                    21.4.4 No Course of Dealing. No single or partial exercise of any right or remedy will preclude any other or further exercise of any right or remedy.</p>

                <p>21.5 Severability. If any part of this&#160;agreement&#160;is declared unenforceable or invalid, the remainder will continue to be valid and enforceable.</p>


            </div>
            <Footer/>
        </div>
    )

}

export default Terms