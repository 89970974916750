import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Header.scss";
import $ from 'jquery';
import {useTransition, animated as a, useSpring, useInView} from '@react-spring/web'
import {useTrail} from '@react-spring/web'
import React, {useCallback, useEffect, useRef, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {parseImage} from "../../script/Utils";

const Header = (props) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [flip, setFlip] = useState("");

    const title = "Social \n" +
        "planning \n" +
        "app";

    const items = (title) ? title.split("\n") : [];
    const config = {mass: 2, tension: 100, friction: 30};
    const [toggle, set] = useState(true);
    const trail = useTrail(items.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 'auto' : 0,
        from: {opacity: 0, x: 20, height: 0}
    });

    const [ref33, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: -50,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFlip("animate-flip")
        }, 300)
    }, [])

    return (
        <div className="header">

            <div ref={ref33} className="container mx-auto navbar-padding flex flex-column justify-center relative">
                <div className={"mx-auto w-full text-center flex flex-col md:flex-row p-5"}>

                    <div className={"flex flex-1 flex-col align-center justify-center"}>
                        <div className={"relative w-100 text-left"}>
                            <p className={"text-lika-grey font-muli font-bold"}>YOUR ALL-IN-ONE</p>
                            {trail.map(({x, height, ...rest}, index) => (
                                <a.div
                                    key={items[index]}
                                    className="trails-text"
                                    style={{
                                        ...rest,
                                        transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                                    }}
                                >
                                    <a.div>
                                        <p className={"text-xl text-white font-bold font-rexton"}>{items[index]}</p>
                                    </a.div>
                                </a.div>
                            ))}

                            <a.div style={springs} className={"flex flex-row mt-5 relative zindex-2"}>
                                <div><a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img
                                    src={"img/apple_download.png"}/></a></div>
                                <div className={"pl-2"}><a href={process.env.REACT_APP_ANDROID_URL}><img
                                    src={"img/google_download.png"}/></a></div>
                            </a.div>

                        </div>
                    </div>
                    <div className={"w-full flex-1 text-left flex flex-col align-center justify-center  md:pl-4"}>
                        <div className={"memory-album"}>
                            <div className={"position-relative"}>
                                <img className={"memory-card flipped " + flip} src={"img/memory_card_1.png"}/>
                                <img className={"memory-card relative zindex-2"} src={"img/memory_card_2.png"}/>
                            </div>
                        </div>
                        <div>
                            <img class={"in5-logo"} src={"img/in5.png"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
