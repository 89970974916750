import React, {useCallback, useEffect, useState} from 'react'
import "./Contact.scss"
import Header from "../../components/header/Header";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";
import Features from "../../components/Features/Features";
import 'alpinejs';
import {Input, Textarea, Button} from "@material-tailwind/react";

const Contact = (props) => {

    const [formLoading, setFormLoading] = useState(false);
    const [formButtonClass, setFormButtonClass] = useState("");
    const [formMessageValue, setFormMessageValue] = useState("");
    const [formMessageClass, setFormMessageClass] = useState("text-white text-sm");

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    const carousel_images = ["img/memory/memory_1.jpg", "img/memory/memory_2.jpg", "img/memory/memory_3.jpg", "img/memory/memory_4.jpg", "img/memory/memory_5.jpg", "img/memory/memory_6.jpg"]
    const carouselList = carousel_images.map((image, index) => {
        return (

            <div key={index} className={"memory-card flex flex-col align-center justify-center"}>
                <div className={"background"}>
                    <img className={"img-hover-zoom"} src={image}/>
                </div>
            </div>

        );
    });
    const handleSubmit = async (e) => {
        // Prevent the browser from reloading the page
        e.preventDefault();
        setFormLoading(true);
        let error = false;

        if (!window.grecaptcha) {
            console.error('reCAPTCHA script not loaded yet');
            return;
        }

        const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, { action: 'submit' });
        if (!token) {
            console.error('reCAPTCHA verification failed');
            return;
        }

        // Send form data (including token) to your backend server for processing
        console.log('Submitting form data with token:', token);

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries()); // Convert FormData to object
        data.token = token;
        // Convert object to JSON string
        const jsonData = JSON.stringify(data);

        // Send data to the API endpoint
        try {
            const response = await fetch('https://webcms.likasocial.com/contact', {
                method: 'POST',
                body: jsonData, // Send data as JSON string in request body
                headers: { 'Content-Type': 'application/json' }, // Set Content-Type header
            });

            // Handle successful submission (e.g., clear form, show success message)
            e.target.reset(); // Clear form after successful submission
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle errors (e.g., show error message to user)
            alert('An error occurred. Please try again later.');
        }

        const timeout = setTimeout(() => {
            // setFormMessageClass("")
            setFormMessageValue("Form submitted successfully! we will contact you shortly.")
            setFormLoading(false);
            setFormButtonClass("hidden")
        }, 800);

        // You can pass formData as a fetch body directly:
        // fetch('/some-api', { method: form.method, body: formData });

        // Or you can work with it as a plain object:
        // const formJson = Object.fromEntries(formData.entries());
        // console.log(formJson);
    }
    function sendForm() {

    }


    return (
        <div className='contact'>

            {/*<Loading/>*/}
            <div className={"partners-section section-padding"}>
                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <p className={"font-rexton pt-2 pb-2 uppercase text-gold"}>Partners</p>
                    <div className={"medium-text-dev"}>
                        <p className={"font-muli text-xsm  text-white"}>We are working on a unique partner platform for
                            Event and Experience
                            providers to promote their offering, a first of its kind Promotional system.
                            Be the first to try it out by signing up in the field below.
                        </p>

                    </div>
                </div>
            </div>


            <div className={"form-section"}>
                <div className="container-flow mx-auto  flex flex-column justify-center relative">
                    <div className={"mx-auto w-full text-center flex flex-row md:flex-row"}>
                        <div
                            className={"form-wrapper card text-left flex-wrap-reverse flex flex-col justify-center basis-[50%] p-5"}>

                                <div className={"form flex flex-col flex-1"}>
                                    <form method="post" onSubmit={handleSubmit}>
                                    <div className={"flex-1"}>
                                        <div className={"p-1"}>
                                            <Input variant="standard" label="COMPANY NAME" name={"company"} placeholder="COMPANY NAME"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col md:flex-row flex-1 mt-4"}>
                                        <div className={"flex-1 p-1"}>
                                            <Input variant="standard" type={"email"} name={"email"} label="COMPANY EMAIL" placeholder="COMPANY EMAIL" required/>
                                        </div>
                                        <div className={"flex-1 p-1"}>
                                            <Input variant="standard" label="PHONE" name={"phone"} placeholder="PHONE" required/>
                                        </div>
                                    </div>
                                    <div className={"flex flex-row flex-1 mt-4"}>
                                        <div className={"flex-1 p-1"}>
                                            <Textarea variant="standard" label="MESSAGE" name={"message"} required/>
                                        </div>
                                    </div>

                                        <div
                                            className="g-recaptcha"
                                            data-sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                                            data-size="invisible"
                                        ></div>

                                    <div><p id={"form-message"}
                                            className={"p-2 " + formMessageClass}>{formMessageValue}</p></div>
                                    <div className={"mt-4"}>
                                        <Button type={"submit"}
                                                className={"text-sm py-2 px-10 font-normal font-muli background-gold text-white " + formButtonClass}
                                                loading={formLoading}>SEND</Button>
                                    </div>

                                    </form>
                                </div>

                        </div>
                        <div className="flex flex-row flex-wrap basis-[50%]">
                            <div className={"bg w-full h-full"}>
                                <div className={"bg-image w-full h-full"}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    )

}

export default Contact